import React from 'react';

const cloudDomainToSandboxDomain: Record<string, string> = {
    'sandbox.cribl-staging.cloud': 'sandbox-test.cribl.io',
    'sandbox.cribl.cloud': 'sandbox.cribl.io',
}

const DEFAULT_CLOUD_DOMAIN_TO_SANDBOX_DOMAIN = 'sandbox.cribl.io';

export const CloudDomainRedirectGuard: React.FC<React.PropsWithChildren<React.ReactNode>> = ({ children }) => {
    if (window.location.hostname.endsWith('.cloud')) {
        const domain = cloudDomainToSandboxDomain[window.location.hostname] || DEFAULT_CLOUD_DOMAIN_TO_SANDBOX_DOMAIN;
        window.location.assign(`https://${domain}${document.location.pathname}`)

        return null;
    }

    return <>{children}</>
}